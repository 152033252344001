<template>
  <div class="wrapper">
    <!-- <h1>THE LESMO时尚潮流电商平台协议与规定</h1> -->
    <div class="imgSrc">
        <img src="@/static/lesmo.png" class="imgSrc"/>
    </div>
    <!-- <div class="lesmo">THE LESMO</div> -->
    <h1>时尚潮流电商平台协议与规定</h1>
    <div class="listItem">
      <ul class="list" style="list-style:none;">
      <!-- <li @click="gonext('/TOS/qualification')" class="uMargin">
        <u>THE LESMO平台经营者资质信息公示</u>
      </li> -->
      <li @click="gonext('/TOS/privacy-policy')" class="uMargin"><u>THE LESMO平台隐私政策</u></li>
      <li @click="gonext('/TOS/service-agreement')" class="uMargin"><u>THE LESMO平台服务协议</u></li>
    </ul>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "home",
  methods: {
    gonext: function (e) {
      this.$router.push(e);
    },
  },
};
</script>
<style lang="stylus" scoped>
.listItem{
  margin-top: 120px
}
.lesmo{
  display block
  text-align center
  font-size 20px
  margin 20px auto
  display block
  font-weight: 800
}
.imgSrc{
  width :176px;
  line-height: 20px
  margin: 0 auto;
  margin-top: 25px;
}
.uMargin
  margin-bottom: 18px
  text-align: center;
  font-size: 16px;
  font-weight: 700;
.wrapper
  position relative
  overflow hidden
  padding 0px 15px
  padding-top 70px
  h1
    text-align center
    width 100%
    font-size 20px
    margin 20px auto
    display block
  .list
    margin 60px auto
    display block
    font-size 28px
    li
      line-height 30px
</style>>
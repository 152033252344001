<template>
  <div>
    <div class="fixed-title">
      <span @click="goback" class="back-btn">返回</span>
      <h2 style="padding-top: 22px">THE LESMO经营者资质信息公示</h2>
    </div>
    <div class="wrapper wrapper-padding-top">
      <p class="text">根据国家相关政策要求对经营者资质信息公示如下</p>

      <h2>THE LESMO, 指THE LESMO平台经营者的单称或合称，包括：</h2>
      <table>
        <tbody>
          <tr>
            <th colspan="3">经营者公司名称</th>
            <th colspan="1">证照公示</th>
          </tr>
          <tr>
            <td colspan="3">
              THE LESMO国内商城平台经营者：<br />
              返得利（深圳）网络科技有限公司
            </td>
            <td colspan="1">
              <router-link to="/TOS/license/1">营业执照</router-link>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              THE LESMO海外商城平台经营者：<br />
              FANDELI(HONG KONG) TRADE CO.,LIMITED
            </td>
            <td colspan="1">
              <router-link to="/TOS/license/2">营业执照</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "qualification",
  methods: {
    goback: function () {
      this.$router.replace("/TOS/TOC");
    },
  },
};
</script>
<style lang="stylus" scoped>
body {
  margin: 0px;
  padding: 0px;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  -webkit-font-smoothing: a;
  -webkit-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ul {
  &.list {
    margin: 60px auto;
    display: block;
  }
  li {
    line-height: 30px;
  }
  a {
    font-style: none;
    color: #333;
  }
}
.wrapper {
  position: relative;
  overflow: hidden;
  padding: 0px 15px;
}
h1 {
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin: 40px auto;
  display: block;
}
h2 {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
}
p {
  &.text {
    font-size: 14px;
  }
}
.fixed-title {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #eee;
  h2 {
    margin-left: 15px;
    margin-top: 0px;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
  .center {
    text-align: center;
  }
}
th {
  text-align: left;
  padding: 5px;
}
table,
th,
td {
  border: 1px solid black;
}
td {
  font-size: 14px;
  padding: 5px;
  a {
    color: #333;
  }
}
img {
  &.document {
    width: 100%;
  }
  &.info {
    width: 20px;
    height: 20px;
  }
}
.wrapper-padding-top {
  top: 70px;
}
.back-btn {
  position: fixed;
  top: 16px;
  right: 15px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.toc-item {
  h3 {
    font-size: 14px;
    span {
      display: inline-block;
      margin-right: 15px;
    }
  }
  p {
    font-size: 14px;
    padding-left: 32px;
  }
  h4 {
    font-size: 14px;
    padding-left: 32px;
  }
}
.full-width {
  width: 95%;
  height: auto;
  display: block;
  padding-left: 5%;
}
</style>
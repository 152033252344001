<template>
<div>
  <div class="fixed-title">
        <h2 style="padding-top: 22px;">THE LESMO商城平台隐私政策</h2>
    </div>
    <div class="wrapper wrapper-padding-top">
        <p class="text">
            <b>引言</b>
            <br><br>
            <p class="contentHeight">THE LESMO（“我们”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《THE LESMO商城平台隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</p>
            <br><br>
            <span class="helpCss">本政策将帮助您了解以下内容：</span>
            <br><br>
            <span class="helpCss">1、我们如何收集和使用您的个人信息</span>
            <br><br>
            <span class="helpCss">2、我们如何使用 Cookie 和同类技术</span>
            <br><br>
            <span class="helpCss">3、我们如何共享、转让、公开披露您的个人信息</span>
            <br><br>
            <span class="helpCss"> 4、我们如何保护和保存您的个人信息</span>
            <br><br>
            <span class="helpCss">5、未成年人信息的保护</span>
            <br><br>
            <span class="helpCss">6、通知和修订</span>
            <br><br>
            <span class="helpCss">7、如何联系我们</span>
            <br><br>
            <p class="contentHeight">本隐私政策与您所使用的THE LESMO商城平台服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</p>
            <br><br>
            <p class="contentHeight">如对本隐私政策或相关事宜有任何问题，您可随时通过在线客服系统与我们联系。</p>
            <br><br><br>

            <h4>目录</h4>
            <br><br>
            <ul style="list-style:none;">
                <li class="helpCss">   一、我们如何收集和使用您的个人信息</li>
                <br><br>
                <li class="helpCss">   二、我们如何使用 Cookie 和同类技术</li>
                <br><br>
                <li class="helpCss">   三、我们如何共享、转让、公开披露您的个人信息</li>
                <br><br>
                <li class="helpCss">   四、我们如何保护和保存您的个人信息</li>
                <br><br>
                <li class="helpCss">   五、未成年人的个人信息保护</li>
                <br><br>
                <li class="helpCss">   六、通知和修订</li>
                <br><br>
                <li class="helpCss">   七、如何联系我们</li>
            </ul>
            <br><br><br>

            <b class="helpCss">一、我们如何收集和使用您的个人信息</b>
            <br><br>
            <p class="contentHeight">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；面部特征；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（交易和消费记录、以及余额、优惠券等虚拟财产信息）；通讯录；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；个人位置信息（包括行程信息、精准定位信息、经纬度等）；</p>
            <br><br>
            <p class="contentHeight">个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：您的财产信息（包括交易记录及余额、优惠券等虚拟财产信息）；面部识别特征；个人身份信息（包括身份证、军官证、护照、驾驶证、户口本）；网络身份识别信息（包括账户名、账户昵称、邮箱地址及与前述有关的密码与密码保护问题和答案）；其他信息（包括通讯录、个人电话号码、手机号码、网页浏览记录、精准定位信息）。</p>
            <br><br>
            <span class="helpCss">我们仅会出于以下目的，收集和使用您的个人信息：</span>
            <br><br>
            <span class="helpCss">（一）您须授权我们收集和使用您个人信息的情形</span>
            <br><br>
            <p class="contentHeight">我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。 我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：</p>
            <br><br>
           <span class="helpCss"> 1、实现网上购物所必须的功能</span>
            <br><br>
            <span class="helpCss">（1）用户注册</span>
            <br><br>
            <p class="contentHeight"> 您首先需要注册一个THE LESMO账户成为THE LESMO用户。当您注册时，您需要至少向我们提供您准备使用的THE LESMO账户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱），我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账户名为您的默认昵称，您可以修改补充您的昵称、性别、生日、兴趣爱好以及您的实名验证相关信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您网上购物的基本功能。</p>
            <br><br>
            <span class="helpCss">（2）商品信息展示和搜索</span>
            <br><br>
            <p class="contentHeight">为了让您快速地找到您所需要的商品，我们可能会收集您使用我们的产品与/或服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。</p>
            <br><br>
            <p class="contentHeight">您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</p>
            <br><br>
            <span class="helpCss">（3）下单</span>
            <br><br>
            <p class="contentHeight"> 当您准备对您购物车内的商品进行结算时，THE LESMO商城平台系统会生成您购买该商品的订单。 您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。</p>
            <br><br>
            <p class="contentHeight">您在THE LESMO购买商品时，您还可能需要根据国家法律法规或服务提供方（国际物流服务商等）的要求提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的身份证、军官证、护照、驾驶证等载明您身份的证件复印件或号码）、您本人的照片或视频、姓名、电话号码等。这些订单中将可能包含您指定的服务地址（可能是您的家庭地址）等信息。</p>
            <br><br>
            <p class="contentHeight">上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。</p>
            <br><br>
            <span class="helpCss">（4）支付功能</span>
            <br><br>
            <p class="contentHeight"> 在您下单后，您可以选择THE LESMO商城平台的关联方或与THE LESMO商城平台合作的第三方支付机构（包括微信支付、支付宝支付及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的THE LESMO订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</p>
            <br><br>
            <span class="helpCss">（5）交付产品或服务功能</span>
            <br><br>
            <p class="contentHeight">在当您下单并在线完成支付后，THE LESMO的关联方或与THE LESMO合作的第三方配送公司（包括顺丰、EMS等，以下称“配送公司”）将为您完成订单的交付。您知晓并同意THE LESMO的关联方或与THE LESMO合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。</p>
            <br><br>
            <span class="helpCss">（6）客服与售后功能</span>
            <br><br>
            <span class="helpCss">我们的客服和售后功能会使用您的账号信息和订单信息。</span>
            <br><br>
            <p class="contentHeight">为保证您的账号安全，我们的客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。</p>
            <br><br>
            <span class="helpCss">2、改进我们的产品与/或服务所必须的功能</span>
            <br><br>
            <p class="contentHeight">我们可能会收集您的订单信息、浏览信息、您的兴趣爱好（您可以在设置中选择）进行数据分析以形成用户画像，用来将您感兴趣的商品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的商品。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。</p>
            <br><br>
            <span class="helpCss">3、保障交易安全所必须的功能</span>
            <br><br>
            <p class="contentHeight"> 为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的订单信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于THE LESMO商城平台系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</p>
            <br><br>
            <span class="helpCss">（二）您可选择是否授权我们收集和使用您的个人信息的情形</span>
            <br><br>
            <p class="contentHeight">1、为使您购物更便捷或更有乐趣，从而提升您在THE LESMO购物体验，我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用这些可以为您所带来购物乐趣的附加功能或在购买某些商品时需要重复填写一些信息。这些附加功能包括：</p>
            <br><br>
            <p class="contentHeight">（1）基于位置信息的个性化推荐功能： 我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您可以购买的商品。</p>
            <br><br>
            <p class="contentHeight">（2）基于摄像头（相机）的附加功能：您可以使用这个附加功能完成视频拍摄、拍照、扫码功能。</p>
            <br><br>
            <p class="contentHeight">（3）基于图片上传的附加功能：您可以在THE LESMO上传您的照片来实现拍照购物功能和晒单及评价功能，我们会使用您所上传的照片来识别您需要购买的商品或使用包含您所上传图片的评价。</p>
            <br><br>
            <p class="contentHeight">2、上述附加功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）、麦克风以及通讯录的访问权限，以实现这些功能所涉及的信息的收集和使用。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
            <br><br>
            <p class="contentHeight">（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
            <br><br>
            <span class="helpCss">1、与国家安全、国防安全有关的；</span>
            <br><br>
            <span class="helpCss">2、与公共安全、公共卫生、重大公共利益有关的；</span>
            <br><br>
            <span class="helpCss">3、与犯罪侦查、起诉、审判和判决执行等有关的；</span>
            <br><br>
            <span class="helpCss"> 4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
            <br><br>
            <span class="helpCss">5、所收集的个人信息是个人信息主体自行向社会公众公开的；</span>
            <br><br>
            <span class="helpCss">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
            <br><br>
            <span class="helpCss">7、根据您的要求签订合同所必需的；</span>
            <br><br>
            <span class="helpCss"> 8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span>
            <br><br>
            <span class="helpCss">9、为合法的新闻报道所必需的；</span>
            <br><br>
            <span class="helpCss">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
            <br><br>
            <span class="helpCss">11、法律法规规定的其他情形。</span>
            <br><br>
            <span class="helpCss">（四）我们从第三方获得您个人信息的情形</span>
            <br><br>
            <p class="contentHeight">我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的THE LESMO账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
            <br><br>
            <span class="helpCss">（五）您个人信息使用的规则</span>
            <br><br>
            <p class="contentHeight">1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</p>
            <br><br>
            <p class="contentHeight">2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</p>
            <br><br>
            <p class="contentHeight"> 3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您通过设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</p>
            <br><br>
            <p class="contentHeight"> 4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
            <br><br>
            <p class="contentHeight">5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</p>
            <br><br>
            <p class="contentHeight"> 6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</p>
            <br><br><br>

            <b class="helpCss">二、我们如何使用 Cookie 和同类技术</b>
            <br><br>
            <span class="helpCss">（一）Cookies的使用</span>
            <br><br>
            <p class="contentHeight"> 1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</p>
            <br><br>
            <p class="contentHeight">2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问THE LESMO在线网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</p>
            <br><br>
            <span class="helpCss">（二）网络Beacon和同类技术的使用</span>
            <br><br>
            <p class="contentHeight">除 Cookie 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络 beacon"）。我们使用网络beacon的方式有：</p>
            <br><br>
            <p class="contentHeight">1、通过在THE LESMO上使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册的THE LESMO用户。</p>
            <br><br>
           <span class="helpCss"> 2、通过得到的cookies信息，为您提供个性化服务。</span>
            <br><br><br>

            <b class="helpCss">三、我们如何共享、转让、公开披露您的个人信息</b>
            <br><br>
            <span class="helpCss">（一）共享</span>
            <br><br>
            <p class="contentHeight">1、我们不会与THE LESMO以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
            <br><br>
            <span class="helpCss">（1）事先获得您明确的同意或授权；</span>
            <br><br>
            <p class="contentHeight">（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
            <br><br>
            <p class="contentHeight">（3）在法律法规允许的范围内，为维护THE LESMO、THE LESMO的关联方或合作伙伴、您或其他THE LESMO用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
            <br><br>
            <p class="contentHeight">（4） 只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</p>
            <br><br>
            <p class="contentHeight">（5）应您需求为您处理您与他人的纠纷或争议；</p>
            <br><br>
            <p class="contentHeight">（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
            <br><br>
            <p class="contentHeight">（7）基于学术研究而使用；</p>
            <br><br>
            <p class="contentHeight">（8）基于符合法律法规的社会公共利益而使用。</p>
            <br><br>
            <p class="contentHeight">2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
            <br><br>
            <p class="contentHeight">3、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。 我们的合作伙伴包括以下类型：</p>
            <br><br>
            <p class="contentHeight">（1）商品或技术服务的供应商。 我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。</p>
            <br><br>
            <p class="contentHeight">（2）第三方商家。 我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</p>
            <br><br>
            <p class="contentHeight">（3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。 我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</p>
            <br><br>
            <p class="contentHeight"> 4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
            <br><br>
            <p class="contentHeight"> 5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护THE LESMO、您或其他THE LESMO客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</p>
            <br><br>
            <span class="helpCss">（二）转让</span>
            <br><br>
            <p class="contentHeight">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
            <br><br>
            <p class="contentHeight">1、事先获得您明确的同意或授权；</p>
            <br><br>
            <p class="contentHeight"> 2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
            <br><br>
            <p class="contentHeight">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
            <br><br>
            <p class="contentHeight"> 4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>        
            <br><br>
            <span class="helpCss">（三）公开披露</span>
            <br><br>
            <p class="contentHeight">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
            <br><br>
            <p class="contentHeight">1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
            <br><br>
            <p class="contentHeight"> 2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</p>
            <br><br><br>

            <b class="helpCss">四、我们如何保护和保存您的个人信息</b>
            <br><br>
            <span class="helpCss">（一）我们保护您个人信息的技术与措施</span>
            <br><br>
            <p class="contentHeight">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
            <br><br>
            <p class="contentHeight">1、数据安全技术措施</p>
            <br><br>
            <p class="contentHeight"> 我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</p>
            <br><br>
            <p class="contentHeight">THE LESMO的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。</p>
            <br><br>
            <span class="helpCss">2、THE LESMO为保护个人信息采取的其他安全措施</span>
            <br><br>
            <p class="contentHeight">安全认证和服务。我们存储您个人数据的底层云技术取得了数据中心联盟颁发的“可信云”认证三级认证、通过了公安部安全等级保护三级认证，同时还获得了ISO27000认证。</p>
            <br><br>
            <p class="contentHeight">加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
            <br><br>
            <p class="contentHeight"> 3、我们仅允许有必要知晓这些信息的THE LESMO及THE LESMO关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与THE LESMO的合作关系。</p>
            <br><br>
            <p class="contentHeight">4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
            <br><br>
            <p class="contentHeight">5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
            <br><br>
            <p class="contentHeight">6、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
            <br><br>
            <p class="contentHeight">7、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。 如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。</p>
            <br><br><br>

            <b class="helpCss">五、未成年人的个人信息保护</b>
            <br><br>
            <p class="contentHeight"> 1、THE LESMO非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您家长或法定监护人的书面同意。THE LESMO根据国家相关法律法规的规定保护未成年人的个人信息。</p>
            <br><br>
            <p class="contentHeight">2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
            <br><br>
            <p class="contentHeight">3、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
            <br><br><br>

            <b class="helpCss">六、通知和修订</b>
            <br><br>
            <p class="contentHeight">1、为给您提供更好的服务以及随着THE LESMO业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在THE LESMO网站、THE LESMO移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问THE LESMO以便及时了解最新的隐私政策。</p>
            <br><br>
            <p class="contentHeight"> 2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</p>
            <br><br>
            <span class="helpCss">本政策所指的重大变更包括但不限于：</span>
            <br><br>
            <p class="contentHeight">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
            <br><br>
            <p class="contentHeight">（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
            <br><br>
            <p class="contentHeight">（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <br><br>
            <p class="contentHeight">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <br><br>
            <p class="contentHeight">（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
            <br><br>
            <p class="contentHeight">（6）个人信息安全影响评估报告表明存在高风险时。</p>
            <br><br>
            <span class="helpCss">3、我们还会将本策略的旧版本存档，供您查阅。</span>
            <br><br><br>

            <b class="helpCss">七、如何联系我们</b>
            <br><br>
            <p class="contentHeight">如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系客服。</p>
            <br><br><br>
            <b class="contentHeight">注：本《THE LESMO商城平台隐私政策》将于2020年12月1日正式生效。</b>
    </div>
</div>
</template>
<script>
export default {
  name: "xieyi",
};
</script>
<style lang="stylus" scoped>
.contentHeight{
  line-height: 23px;
  font-size: 15px;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ul {
  &.list {
    margin: 60px auto;
    display: block;
  }
  li {
    line-height: 12px;
  }
  a {
    font-style: none;
    color: #333;
  }
}
.wrapper {
  position: relative;
  overflow: hidden;
  padding 0px 15px
}
h1 {
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin: 40px auto;
  display: block;
}
h2 {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
}
p {
  &.text {
    font-size: 14px;
  }
}
.fixed-title {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #eee;
  h2 {
    margin-left: 15px;
    margin-top: 0px;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
  .center {
    text-align: center;
  }
}
th {
  text-align: left;
  padding: 5px;
}
table,
th,
td {
  border: 1px solid black;
}
td {
  font-size: 14px;
  padding: 5px;
  a {
    color: #333;
  }
}
img {
  &.document {
    width: 100%;
  }
  &.info {
    width: 20px;
    height: 20px;
  }
}
.wrapper-padding-top {
  top: 70px;
}
.back-btn {
  position: fixed;
  top: 22px;
  right: 15px;
  text-decoration: none;
  color: black;
}
.toc-item {
  h3 {
    font-size: 14px;
    span {
      display: inline-block;
      margin-right: 15px;
    }
  }
  p {
    font-size: 14px;
    padding-left: 32px;
  }
  h4 {
    font-size: 14px;
    padding-left: 32px;
  }
}
.full-width {
  width: 95%;
  height: auto;
  display: block;
  padding-left: 5%;
}
.helpCss{
  font-size:15px;
}
</style>
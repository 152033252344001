<template>
  <div class="">
    <div
      class="mock"
      v-if="mock == 0"
      @touchmove.prevent
      @click="close"
    >
      <img src="../../static/jiantou.png" mode="" class="jiantou">
      <div class="mock_top">
        <img src="../../static/navgitor.png" mode="">
        点击右上角浏览器打开
      </div>
      <div class="donw"> 如浏览器打开失败,请移步桌面直接打开应用 </div>
    </div>
    <div class="noGoods" v-if="nogoods == 0">
        商品已下架
    </div>
    <div class="lodding" v-if="lodding == 1">
      <img src="../../static/lodding.gif" alt="">
      <span>正在加载中...</span>
    </div>
    <div class="nav">
      <img src="../../static/logo.png" mode="">
      <span class="span" style="font-weight: 500">你有一张200元新人专享券,快去使用吧!</span>
      <span class="span" style="font-weight: 900;font-size: 1.82rem;text-shadow: 0px 0.2px black;" @click="godown">打开APP</span>
      <img
        src="../../static/tiaozhuan.png"
        mode=""
        style="width: 2.3rem; height: 2.3rem;position: absolute;
    top: 36%"
      >
    </div>
    <div class="pre_box" v-if="pre==0" @click="close">
      <wc-swiper :autoplay=false class="swiper-container" ref="swiper1" :defaultSlide="preidx"> 
          <wc-slide class="swiper-slide" v-for="(item,index) in imgList" :key="index"><img :src="item" ></wc-slide>
        </wc-swiper>
    </div>
    <div>
      <div class="top">
        <wc-swiper v-if="imgList.length" :autoplay=false class="swiper-container" ref="swiper"  @transitionend="changed"> 
          <wc-slide class="swiper-slide" @click="preview(item,index)" v-for="(item,index) in imgList" :key="index"><img :src="item" ></wc-slide>
        </wc-swiper>
        <div class="swiper-container-thumbs" v-if="imgList.length<=4">
          <div class="img_list1"  ref="img_box">
            <div  class="img_box" v-for="(item,index) in imagesList" :key="index">
                <img :src="item" @click="tabImg1(index)">
                <div :class="test==index?'activeImageItem':''" :index="index"></div>
            </div>
          </div>
        </div>
        <div class="swiper-container-thumbs" v-if="imgList.length>4">
          <div class="img_list"  ref="img_box">
            <div  class="img_box" v-for="(item,index) in imagesList1" :key="index">
                <img :src="item" @click="tabImg(index)">
                <div :class="comCurrentImg==index?'activeImageItem':''" :index="index"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="g_detil">
				<div class="g_title">
					{{brand_name}}
					<span class="g_new" v-if="is_new == 1">新品</span>
				</div>
				<div class="des">
          {{detailList.name}}
				</div>
				<div class="price">
					<span>¥ {{detailList.price | price}}</span>
					<span style="margin-left: 1rem; color: #666666FF" v-if="detailList.europe_price != 0"><s>¥
							{{detailList.europe_price | price}}</s></span> 
				</div>
				<div class="desc">
          {{detailList.description}}
				</div>
			</div>
      <div class="guart_box">
					<div class="guart_list" v-for="(item,index) in shopTop" :key="index">
						<img :src="item.img" mode="aspectFill">
						<span>{{item.name}}</span>
					</div>
			</div>
			<div class="none"></div>
			<div class="guige_box">
				<div class="shop_info" style="padding-top:4rem">
					<span>货&nbsp;&nbsp;&nbsp;号：</span>
					<span>{{detailList.designer_id}}</span>
				</div>
				<div class="shop_info">
					<span>色&nbsp;&nbsp;&nbsp;号：</span>
					<span>{{detailList.colour_num}}</span>
				</div>
        <div class="shop_info size_box">
					<span style="white-space: nowrap;">尺&nbsp;&nbsp;&nbsp;码：</span>
					<div class="size_list">
						<div :class="index == gid ?'size active':'size'" v-for="(item,index) in detailList.sizes"
							:key="index">
							{{item}}
						</div>
					</div>
				</div>
			</div>
			<div class="none"></div>
			<div class="xieyi" @click="goto_xieyi" style="height: 2rem;line-height: 3rem">
				<img src="../..//static/icon_dun.png" mode="" class="left_icon">
				<span style="font-weight: 900;">平台详细购前须知及服务条款</span>
				<img src="../../static/tiaozhuan.png" mode="" class="right_icon">
			</div>
			<div class="none"></div>

			<!-- 其他推荐 -->
			<div class="shop_box">
				<div class="shop_title">
					<span>其他推荐</span>
				</div>
				<!-- 其他推荐列表 -->
				<div class="friend_box" >
					<div class="shop">
						<div class="shoplist" v-for="(item, index) in shopList" :key="index">
							<img :src="item.cover" class="shop_img"	@click="goto_detail(item)">
							<div class="shop_inf">
								<div class="shop_titlename"> {{item.brand_name}}</div>
								<div class="langwang" v-if="!item.type">{{ item.name }}</div>
								<div>
									<div class="pmoney" v-if="!item.type">￥{{ item.price }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- div占位 -->
			<div class="" style="width: 100%; height: 13rem;"></div>
			<div class="bottom" v-show="hide==1">
        <div class="button" @click="goLink">打开APP查看</div>
        <div class="blank"></div>
			</div>
    </div>
  </div>
</template>

<script>
//import Swiper from 'swiper'
import axios from "axios"
export default {
  created:function(){
    this.id=this.$route.params.id
  },
  mounted:function(){
    window.addEventListener('scroll', this.handleScroll, true)
    this.getInfo()
    this.getOther()
  },
   watch: {
            $route(to, from) {
                this.$router.go(0)
            }
        },
  data() {
			return {
        swiper:{},
        currentIndex:0,
        id:'',
				mock:1,
				urls:'',
				hide: 1,
				type1: 0,
				test: 0,
        lodding:0,
				comCurrentImg: 0,
				scrollImgList: 0,
				imgLeftList: [],
				act: 0,
				link: '',
				choose_img: 0,
				share_cover: '',
				indicatorDots: false,
				autoplay: false,
				interval: 2000,
				duration: 500,
				gid: -1,
				imagesList1: [],
				is_new: 0,
				imagesList: [],
				guige: [],
				brand_name: '',
				goodsinfo: '',
				shopList: [],
				size: '',
				imgList: [],
				userinfo: '',
				islike: 0,
				detailList: [],
				shopTop: [{
						name: '正品保障',
						img: require('../../static/icon_bao.png')
					},
					{
						name: '假一赔十',
						img: require('../../static/icon_pei.png')
					},
					{
						name: '全网最低',
						img: require('../../static/icon_sheng.png')
					},
					{
						name: '物流跟踪',
						img: require('../../static/icon_wuliu.png')
					}
				],
				showImg: '',
        preimg:'',
        pre:1,
        preidx:0,
        nogoods:1,
			};
		},
    methods:{
      handleScroll(){
      },
      scrollTo(){
      this.$refs.img_box.scrollLeft = this.comCurrentImg * 40
      },
      changed(e) {
        this.test = this.imagesList.length - 1 - e
        this.tabImg(e)
       console.log('滑动',e)
      },
      tabImg(e){
        console.log('点击',e)
        this.$refs.swiper.slideTo(e)
        this.comCurrentImg=e
        this.scrollTo()
      },
      tabImg1(e){
        console.log('点击',e)
        let i = this.imagesList.length - 1 - e
        this.$refs.swiper.slideTo(i)
        this.comCurrentImg=e
        this.scrollTo()
      },
      goto_xieyi(){
        this.$router.push({
        path:'/TOS/service-agreement'
      })
      },  
      preview(e,d){
      console.log('预览',e,d)
      this.pre = 0
      this.preimg=e
      this.preidx=d
      },
      close(){
        this.mock=1
        this.pre = 1
        this.nogoods = 1
      },
      godown(){
        this.$router.push({
        path:'/app/download'
      })
      },
      getInfo() {
        let that = this 
        let params =this.id
        axios({
        method:'get',
        url:'goods/'+params,
        headers: {
            'Accept': 'application/x.app_api.v1+json',
						'content-type': 'application/x-www-form-urlencoded',
						'Authorization': 'Bearer ' + 111
        },
        params:{}
        })
        .then((res)=>{
         	if (res.data.code == 0) {
							that.is_new = res.data.data.is_new
							var list = res.data.data
							var skuids = ''
							list.sizes.forEach(item => {
								skuids += item + '/'
							})
							skuids = skuids.substring(0, skuids.length - 1)
							this.goodsinfo = list.brand.name + ' ' + list.category_name + ' ' + list.name +
								' ' + skuids + ' ' + list.description + ' ￥' + list.price + '起'
							console.log(this.goodsinfo)
							var arr = []
							var arr1 = []
							var images = list.images
							images.forEach(item => {
								arr.unshift(list.image_domain + item)
							})
							images.forEach(item => {
								arr1.unshift(list.image_domain + item)
							})
							arr.push(res.data.data.cover)
							arr1.push(res.data.data.cover)
							this.detailList = res.data.data
              this.goods_name= res.data.data.name
              this.imagesList = arr1
              this.imagesList1 = arr.reverse()
              this.test = arr.length - 1
              this.type1 = 0
              this.imgList = arr
              this.type1 = 1
							this.showImg = arr[arr.length - 1]
							this.islike = res.data.data.is_collected
							this.brand_name = res.data.data.brand.name
              document.title = this.goods_name
              this.lodding = 0
						}else{
              console.log('请求失败')
            }
        })
        .catch(function (error) {
          that.nogoods = 0
          setTimeout(function(){
            that.nogoods = 1
          },1000)
        })
			},
      goLink:function(){
				let that = this
				let ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger"){
					//是
					console.log('是微信')
					this.mock = 0
				}else{
					//不是
					setTimeout(function(){
						console.log(that.urls)
						window.location.href = "lang://pages/index/shopDetails? id=" + that.id
					},1)
          window.setTimeout(function() {
                that.$router.push('/app/download')//android下载地址  
            }, 500);
				}
			},
/*       goto_detail:function(e){
        let id =e.id
        this.$router.push({
        path:`/app/goods/${id}`
      })
			}, */
      goto_detail:function(e){
        this.lodding = 1
        this.id = e.id
        this.detailList = []
        this.goods_name= []
        this.imagesList = []
        this.imagesList1 = []
        this.test =0
        this.imgList = []
        this.showImg = []
        this.islike = []
        this.brand_name = []
        document.title = []
        this.shopList= []
        this.getInfo()
        this.getOther()
        this.$router.push({
        path:'/app/goods/'+ e.id
        })
      },
      getOther() {
        axios({
        method:'get',
        url:'other-recommended-goods',
        headers: {
            'Accept': 'application/x.app_api.v1+json',
						'content-type': 'application/x-www-form-urlencoded',
						'Authorization': 'Bearer ' + 111
        },
        params:{}
        })
				.then(res=>{
						if (res.data.code == 0) {
							this.shopList = res.data.data
						}
					})
        
			},
    }
};
</script>

<style lang="stylus" scoped>
  *
    font-family pf
  .noGoods
    position fixed
    width 14rem
    height 8rem
    left 0
    right 0
    top 0
    bottom 0
    margin auto
    text-align center
    background-color rgba(5,6,6,0.6)
    color #fff
    font-size 1rem
    line-height 8rem
    border-radius 1rem
  .lodding
    position fixed
    width 100%
    height 100%
    background-color rgba(5,6,6,0.5)
    z-index 99
    img 
      position relative
      top 44%
      height 6rem
      left 45%
    span 
      font-size 2rem
      position absolute
      top 49.5%
      color black
      left 42%
  .donw 
    position relative
    display flex
    justify-content center
    align-items center
    font-size 2rem
  .wc-pagination
    display none
  .bottom
    padding 0rem 4rem
    height 11rem
    position fixed
    bottom 0
    left 0
    width 100%
    background white
    .button
      text-align center
      line-height 8rem
      width 50rem
      height 8rem
      background-color black
      color #fff
      font-size 2.27rem
      position absolute
      top 0
      z-index 1
      left 7.2%
    .blank
      position absolute
      width 100%
      background-color #fff
      height 3rem
      top 6rem
      width 100vw
      left 0
  .g_detil
    padding-left 3rem
    font-size 2rem
    margin-top 2rem
    .g_title
      font-size 2.24rem
      font-weight 600
      font-family Montserrat-Medium
      .g_new
        color #C30F06
        font-size 2rem
        border 1px solid #C30F06
        padding 0 1rem
        margin-left 1.2rem
    .des
      font-size 1.92rem
      margin 2rem 0rem
      font-weight 100
    .price
      font-family Montserrat-Medium
      font-size 1.92rem
      color #C30F06
      font-weight 600
    .desc
      margin 2rem 0rem
      font-size 1.92rem
      color #666
  .guart_box
    display flex
    justify-content space-around
    background-color #fff
    border-top 1px solid #eee
    padding 2rem
    .guart_list
      display flex
      align-items center
    img 
      width 3.1rem
      height 3.1rem
      margin-right 1rem
    span 
      font-size 2rem
      white-space nowrap
  .pre_box
    position fixed
    width 100%
    background-color #666
    height 100%
    z-index 9999
    color #FFFFFF
    .swiper-container
      top 20%
      height 60rem
      .swiper-slide
        width 60rem
        text-align center
        line-height 500px
        img
          width 100%
          height 100%
          margin 0 auto
  .mock
    position fixed
    width 100%
    background-color rgba(5,6,6,0.5)
    height 100%
    z-index 9999
    top 0
    color #FFFFFF
    .jiantou
      position relative
      width 30rem
      height 30rem
      z-index 9999
      left 50%
    .mock_top
      position relative
      display flex
      justify-content center
      align-items center
      font-size 4rem
      font-weight 900
      img
        width 10rem
        height 10rem
  .nav
      position fixed
      top 0
      width 100%
      height 8rem
      background-color #DDDDDD
      line-height 8rem
      align-items center
      padding-left 5rem
      z-index 100
      img
        width 5rem
        height 5rem
        line-height 8rem
        vertical-align middle
      .span
        margin-left 1rem
        font-size 2rem
        font-family Montserrat-Medium
  .top
    margin-top 8rem
    .swiper-container
      height 60rem
      .swiper-slide
        width 60rem
        text-align center
        line-height 500px
        img
          width 100%
          height 100%
          margin 0 auto
    .swiper-container-thumbs  
      width 100%
      .img_list1
        overflow-x scroll    
        display flex
        padding-top 2rem
        flex-direction row-reverse
        .img_box
          text-align center
          .activeImageItem
            border-bottom 1px solid #000
            width 7rem
            margin-left 2.6rem
          img
            margin 0 1rem
            width 10rem
            height 10rem
      .img_list
        overflow-x scroll    
        display flex
        padding-top 2rem
        .img_box
          text-align center
          .activeImageItem
            border-bottom 1px solid #000
            width 7rem
            margin-left 2.6rem
          img
            margin 0 1rem
            width 10rem
            height 10rem
  .none 
    width 100%
    height 1.8rem
    background-color #F8F8F8
  .guige_box
    font-weight 400 !important
    padding-left 3rem
    background-color #ffffff
    .shop_info
      padding 1rem
      span 
        font-size 1.76rem
        font-family Montserrat-Medium
        padding-right 2rem
    .size_box
      display flex
      padding-top 2rem
      .size_list 
        display flex
        flex-wrap wrap
        .size
          margin-bottom 1rem
          font-size 2rem
          border 1px solid #999999
          padding 1rem 1.4rem
          font-family Montserrat-Medium
          line-height 2rem
          margin-right 2rem
          text-align center
        .size_btn
          font-size 2rem
          background #EEEEEE
          padding 1rem 3rem
.xieyi 
  padding 3rem 4rem
  background-color #fff
  display flex
  align-items center
  vertical-align middle
  span 
    font-size 2rem
    text-indent 1rem
    display inline-block
    width 20rem
    white-space nowrap
  .left_icon 
    width 3rem
    height 3rem
  .right_icon
    width 2.2rem
    height 2.2rem
    margin-left 28rem
.shop_box
  .shop_title
    padding-left 3rem
    padding-top 3rem
    span
      font-size 2.3rem
  .friend_box
    overflow-x scroll
    .shop
      width 100%
      margin-bottom 1rem
      display flex
      justify-content space-between
      padding 0
      flex-wrap nowrap
      .shoplist 
        width 24rem
        border-radius 1rem
        background #fff
        padding-bottom 2rem
        text-align center
        margin-right 1rem
        .shop_img
          width 18rem
          height 24rem
          display inline-block
        .shop_titlename 
          width 24rem
          text-align center
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          font-family Montserrat-Medium
          font-weight bold
          font-size 2.08rem
        .langwang
          width 24rem
          text-align center
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          color #7d7d7d
          padding 1rem 0rem
          font-size 1.92rem
          font-weight 500
          font-family Montserrat-Medium
          text-align center
        .pmoney
          padding 0 2rem
          font-size 2.08rem
          color #b7372e
          font-family Montserrat-Medium
          display flex
          font-weight 600
          justify-content center
</style>
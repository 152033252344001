<template>
<div>
  <div>
    <div
      class="mock"
      v-if="mock == 0"
      @click="close1"
       @touchmove.prevent
    >
      <img src="../../static/jiantou.png" mode="" class="jiantou">
      <div class="mock_top">
        <img src="../../static/navgitor.png" mode="">
        点击右上角浏览器打开
      </div>
      <div class="donw">如浏览器打开失败,请移步桌面直接打开应用</div>
    </div>
    <div class="top">
      <img class="logo" src="../../static/bm.png">
      <img class="price" src="../../static/price.png" mode="">
    </div>
    <div class="content">
      <div class="color weight" style="font-size: 2rem">
        *与新人优惠券不冲突
      </div>
      <div class="color weight" style="margin-top: 5rem; font-size: 3.5rem">
        致热爱时尚的你
      </div>
      <div class="" style="margin-top: 2rem; font-size: 6rem">
        ￥400 邀请优惠券
      </div>
      <div class="color weight" style="margin-top: 4rem; font-size: 2rem">
       邀请码: {{regcode}}
      </div>
      <div class="color weight" @click="open" style="margin-top:3rem; font-size: 2rem;border-bottom: 1px solid #757575;">
        查看活动规则
      </div>
      <div class="bord" @click="goLink" style="font-size: 2.27rem">立即注册并领取</div>
    </div>
    <div class="goods">
      <div class="tex">商品推荐</div>
      <div class="goods_list">
       <!--  <div class="goods_box" v-for="(item,index) in shopList" :key="index" @click="goto_detail(item)"> -->
          <div class="goods_box" v-for="(item,index) in shopList" :key="index">
          <img class="banna" :src="item.cover" alt="">
          <div class="name">{{item.brand_name}}</div>
          <div class="des">{{item.name}}</div>
          <div class="price">
					<span>¥ {{item.price | price}}</span>
					<span style="margin-left: 1rem; color: #666666FF" v-if="item.europe_price != 0"><s>¥
							{{item.europe_price | price}}</s></span> 
				</div>
        </div>
      </div>
      <div class="down" @click="goDown">下载APP 浏览更多商品</div>
    </div>
    <div class="roules" v-show="boxshow"  @touchmove.prevent>
      <div class="mask" @click="close"></div>
      <transition name="slide">
        <div class="popup-content" v-show="boxshow">
          <div class="title">活动规则</div>
          <div class="close" @click="close">X</div>
          <div class="rule">
            <div class="text">
              新用户定义：接受本邀请时，尚未注册成为【THE LESMO】平台的用户，且首次登陆APP设备为未登陆过【THE LESMO】平台的设备。
            </div>
            <div class="text">
              邀请人指向用户发送链接的邀请发起人；接受邀请发起的人即为被邀请人。如存在多个邀请人向同一新用户发出邀请的情形，将以新用户首先接受的邀请来判断邀请人。
            </div>
            <div class="text">邀请人可随时向新用户发起邀请，不限次数。</div>
            <div class="text">
              新用户作为被邀请人，如通过邀请人发送的链接授权注册【THE LESMO】平台，并登陆APP，则可获得邀请优惠券礼包。该礼包中含的优惠券，在首次登陆APP后，有效期30天，可根据优惠券使用规则用于【THE LESMO】平台的购物。
            </div>
            <div class="text">
              只要被邀请人通过邀请发起页面注册领券，并登陆APP，则视为邀请成功，邀请人将会获得一份邀请优惠券礼包。
            </div>
            <div class="text">
              邀请人及被邀请人的邀请优惠券皆不可以转让给其他用户，仅限本人账户使用。有其他问题可咨询APP客服，以【THE LESMO】平台最终解释权为准。
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</div>
</template>
<script>
import axios from "axios"
export default {
  created:function(){
    console.log(this.$route.params.reg)
    this.regcode=this.$route.params.reg
  },
  mounted(){
    this.getOther()
  },
  data(){
    return{
       boxshow: false,
       mock:1,
       regcode:'',
       shopList:''
    }
  },
  methods:{
  goto_detail:function(e){
      let that = this
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger"){
        //是
        console.log('是微信')
        this.mock = 0
      }else{
        //不是
        setTimeout(function(){
          window.location.href = "lang://pages/index/shopDetails?id=" + e.id
        },1)
      }
    },
    open:function(){
    this.boxshow = !this.boxshow;
    },
    close:function(){
      this.boxshow = !this.boxshow;
    },
    close1:function(){
      this.mock = 1
    },
    goLink:function(){
      let that = this 
      let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger"){
					//是
					console.log('是微信')
					this.mock = 0
          return
				}
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        var loadDateTime = new Date();
        window.location = "lang://reg=" + that.regcode ;//schema链接或者universal link
        window.setTimeout(function() { //如果没有安装app,便会执行setTimeout跳转下载页
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
                that.$router.push('/app/download') //ios下载地址  
            } else {
                window.close();
            }
        }, 500);
        
    } else if (navigator.userAgent.match(/android/i)) {
        var state = null;
        try {
            window.location = "lang://reg=" + that.regcode; //schema链接或者universal link
            window.setTimeout(function() {
                that.$router.push('/app/download')//android下载地址  
            }, 500);
        } catch (e) {}
      }
    },
    goDown:function(){
      this.$router.push('/app/download')
    },
    getOther() {
      axios({
      method:'get',
      url:'other-recommended-goods',
      headers: {
          'Accept': 'application/x.app_api.v1+json',
          'content-type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + 111
      },
      params:{}
      })
      .then(res=>{
          if (res.data.code == 0) {
            this.shopList = res.data.data
            console.log(this.shopList)
          } 
        })
			},
  }
};
</script>

<style lang="stylus" scoped>
  .goods
    height 100%
    margin 0 1rem
    box-sizing border-box
    .tex
      font-weight 600
      border-bottom #000000 2px solid
      padding-bottom 2rem
      font-size 3rem
    .goods_list
      margin-top 6rem
      margin-bottom 5rem
      display flex
      flex-wrap wrap
      justify-content center
      .goods_box
        width 50%
        text-align center
        .banna
          width 25rem
          height 25rem
        .name
          font-size 2.08rem
          font-family Montserrat-Medium
          font-weight bold
        .des
          color #7D7D7D
          font-size 1.76rem
          font-family Montserrat-Medium
          font-weight 500
          padding 1.4rem 0rem
        .price
          color #BB3D31FF
          font-family Montserrat-Medium
          font-weight bold
          font-size 2rem
          padding-bottom 3rem
    .down
      text-align center
      border #A0A0A0 1px solid
      width 50rem
      height 9.5rem
      color #A0A0A0
      font-weight 800
      line-height 9.5rem
      margin 0 auto
      margin-bottom 4rem
      font-size 2.27rem  
  .roules
    .mask
      position fixed
      width 100%
      left 0
      height 100%
      top 0
      background-color rgba(0, 0, 0, 0.4)
      transition all 0.2s ease-in
    .popup-content 
      position fixed
      height 74rem
      bottom 0
      left 0
      width 100%
      background-color #ffffff
      -webkit-transition all 0.2s ease-in
      transition all 0.2s ease-in
      overflow hidden
      .title
        text-align center
        font-size 2rem
        padding-top 4rem
        font-weight 600
      .close
        position absolute
        right 5rem
        top 4rem
        font-size 2rem
      .rule
        width 90%
        margin 0 auto
        .text
          font-size 1.8rem
          margin-top 4rem
          margin-bottom 2rem
          font-weight 600
	.mock{
		position: fixed;
		width: 100%;
		background-color: rgba(5,6,6,0.5);
		height: 100%;
		z-index: 9999;
		top: 0;
		color: #FFFFFF;
		.jiantou {
			position: relative;
			width:30rem;
			height:30rem;
			z-index: 9999;
			left: 46%;
		}
		.mock_top{
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 4rem;
			font-weight: 900;
			img {
				width: 8rem;
				height: 8rem;
			}
		}
		.donw {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2.6rem;
		}
	}
.slide-enter-active {
    animation-name: slideInUp;
    animation-duration: 0.2s;
    animation-fill-mode: both;
}
.slide-leave-active {
    animation-name: slideOutDown;
    animation-duration: 0.2s;
    animation-fill-mode: both;
}
@keyframes slideInUp {
    0% {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translateZ(0);
    }
}
@keyframes slideOutDown {
    0% {
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        transform: translate3d(0, 100%, 0);
    }
}
.delay-leave-active {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
	.top
		width 100%
		height 68rem
		.logo
			position relative
			height 57.58rem
			width 100%
			margin-left auto
			margin-right auto
			margin-bottom 5rem
		.price
      position relative
      bottom 27rem
      height 28.84rem
      width 44.91rem
      left 8rem
	.content
		display flex
		flex-direction column
		text-align center
		align-items center
		margin-bottom 10rem
		.weight
			font-weight 900
		.color
			color #757575
		.bord
			text-align center
			border 1px solid #00000
			width 55rem
			height 8.7rem
			color #FFFFFF
			background-color #000000
			line-height 8.7rem
			margin-top 5rem
</style>
<template>
  <div>
    <div class="fixed-title">
      <h2 style="padding-top: 22px">THE LESMO平台服务协议</h2>
    </div>
    <div class="wrapper wrapper-padding-top">
      <p class="font15">更新日期：2020年12月3日</p>

      <p class="text contentHeight">
        本协议是您与THE LESMO商城平台（网址：cngenesis.com，手机APP：THE LESMO）（本站）所有者樂茂時尚國際有限公司，经营者樂茂時尚國際有限公司（以下简称为“THE LESMO”或者“我们”）之间就THE LESMO商城平台服务等相关事宜所订立的契约，<span
          style="border-bottom: solid 1px #000000"
          >请您仔细阅读本注册协议，您点击勾选“同意”按钮后，本协议即已经生效，并构成对双方有约束力的法律文件</span
        >。 <br /><br />

        <b>一、服务条款的确认和接纳</b>
        <br /><br />
        本站的各项电子服务的所有权和运作权归THE LESMO所有。您同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定执行。
        <br /><br />
        您点击同意本协议的，即视为您确认自己具有享受本站服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
        <br /><br />
        如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本站。
        <br /><br />
        THE LESMO保留在中华人民共和国大陆地区相关法律允许的范围内独自决定拒绝提供服务、关闭用户账户、清楚或编辑内容或取消订单的权利。
        <br /><br /><br />

        <b>二、本站服务范围</b>
        <br /><br />
        THE LESMO通过互联网依法为您提供互联网信息等服务，您在完全同意本协议及本站规定的情况下，方有权使用本站的相关服务。
        <br /><br />
        您必须自行准备如下设备和承担如下开支：
        1.上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；2.
        上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
        <br /><br /><br />

        <b>三.账户注册与使用</b>
        <br /><br />
        1. 用户资格
        <br /><br />
        您确认，在您开始注册程序使用本站服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
        <br /><br />
        2. 账户使用
        <br /><br />
        由于您的本站账户关联您的个人信息及本站商业信息，您的本站账户仅限您本人使用。未经本站同意，您直接或间接授权第三方使用您的本站账户或获取您的账户项下信息的行为无效。如果本站根据本站平台规则中约定的违约认定程序及标准判断您本站账户的使用可能危及您的账户安全及/或本站信息安全的，本站可拒绝提供相应服务或终止本协议。
        <br /><br />
        3. 实名认证
        <br /><br />
        作为本站经营者，为使您更好地使用本站的各项服务，保障您的账户安全，本站可要求您按国家法律规定完成实名认证。
        <br /><br />
        4. 注册信息管理
        <br /><br />
        在使用本站服务时，您应当按本站页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便我们或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。
        <br /><br />
        5. 会员名的合法性
        <br /><br />
        您设置的本站会员名不得违反国家法律法规及本站规则关于会员名的管理规定，否则我们可回收您的本站会员名。本站会员名的回收不影响您以邮箱、手机号码登录本站平台并使用本站服务。
        <br /><br />
        6. 更新维护
        <br /><br />
        您应当及时更新您提供的信息，在法律有明确规定要求本站作为平台服务提供者必须对部分用户（如平台商家或消费者等）的信息进行核实的情况下，我们将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。
        <br /><br />
        7. 账户安全
        <br /><br />
        您的账户为您自行设置并由您保管，本站任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开本站。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，我们并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
        <br /><br />
        除本站存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。
        <br /><br />
        如发现任何未经授权使用您账户登录本站或其他可能导致您账户遭窃、遗失的情况，建议您立即通知我们。您理解我们对您的任何请求采取行动均需要合理时间，且我们应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除我们存在法定过错外，我们不承担责任。
        <br /><br /><br />

        <b>四.THE LESMO商城平台服务及规范</b>
        <br /><br />
        1. 服务概况
        <br /><br />
        您有权在本站上享受商品及/或服务的购买与评价、交易争议处理等服务。本站提供的服务内容众多，具体您可登录本站浏览。
        <br /><br />
        2. 商品及/或服务的购买
        <br /><br />
        当您在本站购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱本站正常交易秩序的行为。基于维护本站交易秩序及交易安全的需要，本站发现上述情形时可主动执行关闭相关交易订单等操作。
        <br /><br />
        3. 交易争议处理
        <br /><br />
        您在本站交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        <br /><br />
        3.1 与争议相对方自主协商；
        <br /><br />
        3.2 由本站客服提供的争议调解服务；
        <br /><br />
        3.3 请求消费者协会或者其他依法成立的调解组织调解；
        <br /><br />
        3.4 向有关行政部门投诉；
        <br /><br />
        3.5 根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；
        <br /><br />
        3.6 向人民法院提起诉讼。
        <br /><br />
        4. 责任限制
        <br /><br />
        本站依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，我们并不承担相应的违约责任：
        <br /><br />
        4.1 因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
        <br /><br />
        4.2 因电力供应故障、通讯网络故障等公共服务因素或第三人因素；
        <br /><br />
        4.3
        在THE LESMO已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
        <br /><br />
        THE LESMO商城平台仅向您提供本站服务，您了解本站上的信息系用户自行发布，且可能存在风险和瑕疵。我们将通过依法建立相关检查监控制度尽可能保障您在本站上的合法权益及良好体验。同时，鉴于本站具备存在海量信息及信息网络环境下信息与实物相分离的特点，我们无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。
        <br /><br /><br />

        <b>五.用户信息的保护及授权</b>
        <br /><br />
        1. 个人信息的保护
        <br /><br />
        我们非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用我们提供的服务时，您同意我们按照在本站上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。我们希望通过隐私权政策向您清楚地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。
        <br /><br />
        2. 非个人信息的保证与授权
        <br /><br />
        您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，我们可对您发布的信息依法或依本协议进行删除或屏蔽。
        <br /><br />
        您应当确保您所发布的信息不包含以下内容：
        <br /><br />
        2.1 违反国家法律法规禁止性规定的；
        <br /><br />
        2.2 政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
        <br /><br />
        2.3 欺诈、虚假、不准确或存在误导性的；
        <br /><br />
        2.4 侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
        <br /><br />
        2.5 侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
        <br /><br />
        2.6
        存在可能破坏、篡改、删除、影响本站任何系统正常运行或未经授权秘密获取本站及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
        <br /><br />
        2.7其他违背社会公共利益或公共道德或依据相关本站协议、规则的规定不适合在本站上发布的。
        <br /><br />
        对于您提供、发布及在使用本站服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予我们及其关联公司获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意我们及其关联公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
        <br /><br /><br />

        <b>六.用户的违约及处理</b>
        <br /><br />
        1. 违约认定
        <br /><br />
        发生如下情形之一的，视为您违约：
        <br /><br />
        1.1 使用本站服务时违反有关法律法规规定的；
        <br /><br />
        1.2 违反本协议约定的。
        <br /><br />
        为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，我们可在本站规则中约定违约认定的程序和标准。如：我们可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。
        <br /><br />
        2. 违约处理措施
        <br /><br />
        您在本站上发布的信息构成违约的，我们可根据相应规则立即对相应信息进行删除、屏蔽处理
        <br /><br />
        您在本站上实施的行为，或虽未在本站上实施但对本站及其用户产生影响的行为构成违约的，我们可依据相应规则对您执行限制、中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，我们可查封您的账户，终止向您提供服务。
        <br /><br />
        我们可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在本站上予以公示。
        <br /><br />
        3. 赔偿责任
        <br /><br />
        如您的行为使我们及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿我们及/或其关联公司的上述全部损失。如您的行为使我们及/或其关联公司遭受第三人主张权利，我们及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
        <br /><br />
        4. 商业贿赂
        <br /><br />
        如您向我们及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，我们可立即终止与您的所有合作并向您收取违约金及/或赔偿金，该等金额以我们因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
        <br /><br />
        如您因严重违约导致我们终止本协议时，出于维护平台秩序及保护消费者权益的目的，我们及/或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施。如THE LESMO与您签署的其他协议及我们及/或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则我们出于维护平台秩序及保护消费者权益的目的，可在收到指令时中止甚至终止协议。
        <br /><br /><br />

        <b>七.协议的变更</b>
        <br /><br />
        我们可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议。如您不同意变更事项，您有权于变更事项确定的生效日前联系我们反馈意见。如反馈意见得以采纳，我们将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用本站服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用本站服务，则视为您同意已生效的变更事项。
        <br /><br /><br />

        <b>八.协议的终止</b>
        <br /><br />
        用户发起的终止
        <br /><br />
        您有权通过以下任一方式终止本协议：
        <br /><br />
        1.在满足我们公示的账户注销条件时您通过申请注销您的账户的；
        <br /><br />
        2.变更事项生效前您停止使用并明示不愿接受变更事项的；
        <br /><br />
        3.您明示不愿继续使用本站服务，且符合我们终止条件的。
        <br /><br />
        我们发起的终止
        <br /><br />
        出现以下情况时，我们可以终止本协议：
        <br /><br />
        1.您违反本协议约定，我们依据违约条款终止本协议的；
        <br /><br />
        2.您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，我们依据本站规则对您的账户予以查封的；
        <br /><br />
        3.除上述情形外，因您多次违反本站规则相关规定且情节严重，我们依据本站规则对您的账户予以查封的；
        <br /><br />
        4.您的账户被本站依据本协议回收的；
        <br /><br />
        5.其它应当终止服务的情况。
        <br /><br /><br />

        <b>九.管辖法律和可分割性</b>
        <br /><br />
        本协议之效力、解释、变更、执行与争议解决均适用于中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。
        <br /><br />
        因本协议产生或与本协议有关的争议，您可于我们以友好协商的方式予以解决或提交有管辖权的人民法院予以裁决。
        <br /><br />
        本协议任何条款被有管辖权的人民法院裁定为无效，不应影响其他条款或其任何部分的效力，您与我们仍应当履行。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="stylus" scoped>
.font15{
  font-size: 15px;
}
body 
  margin 0px
  padding 0px
  font-family PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif"
  -webkit-font-smoothing antialiased
  -webkit-text-size-adjust 100% !important
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)
.contentHeight{
  line-height: 23px;
  font-size: 15px;
}
ul 
  &.list 
    margin 60px auto
    display block
  li 
    line-height 30px
  a 
    font-style none
    color #333
.wrapper 
  position relative
  overflow hidden
  padding 0px 15px
h1 
  width 100%
  font-size 20px
  margin 40px auto
  display block
h2 
  font-size 16px
  margin-top 30px
  margin-bottom 50px
p 
  &.text 
    font-size 14px
.fixed-title 
  position fixed
  top 0px
  left 0px
  width 100%
  height 60px
  background #fff
  z-index 999
  border-bottom 1px solid #eee
  h2 
    margin-left 15px
    margin-top 0px
table 
  border-collapse collapse
  width 100%
  .center 
    text-align center
th 
  text-align left
  padding 5px
table,
th,
td 
  border 1px solid black
td 
  font-size 14px
  padding 5px
  a 
    color #333
img 
  &.document 
    width 100%
  &.info 
    width 20px
    height 20px
.wrapper-padding-top 
  top 70px
.back-btn 
  position fixed
  top 22px
  right 15px
  text-decoration none
  color black
.toc-item 
  h3 
    font-size 14px
    span 
      display inline-block
      margin-right 15px
  p 
    font-size 14px
    padding-left 32px
  h4 
    font-size 14px
    padding-left 32px
.full-width 
  width 95%
  height auto
  display block
  padding-left 5%
</style>
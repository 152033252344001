<template>
  <div>
    <div class="container">
      <div style="height: 50px"></div>
      <div class="logo" @click="test"></div>
      <div class="title">THE LESMO</div>
      <div class="subhead">高端多元化时尚精品购物商场</div>
      <div class="intro">
        <p>商城汇聚名牌服装、包袋、鞋履和配饰等品类的海量商品。</p>
        <p>100%正品承诺，假一赔十，全球速递。</p>
      </div>

      <div class="download-button">
        <a
          href="https://apps.apple.com/cn/app/id1543361892#?platform=iphone"
          class="button button-ios right-interval"
        ></a>
        <a
          href="https://app.mi.com/details?id=com.cngenesis.wave"
          class="button button-android left-interval"
        ></a>
      </div>
      <div class="download-text">
        <div class="text right-interval">点击下载客户端</div>
        <div class="text left-interval">点击下载客户端</div>
      </div>
      <div class="download-detail">
        <div class="detail right-interval">
          <img src="../../static/download-detail1.png" />
        </div>
        <div class="detail left-interval">
          <img src="../../static/download-detail2.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "down",
  data() {
    return {
      url: "https://app.mi.com/details?id=com.cngenesis.wave",
    };
  },
  methods: {
    test:function(){
      let id =1045490
      this.$router.push({
        path:`/app/goods/${id}`
      })
    },
    godown: function (e) {
      if (e == 1) {
        window.open(
          "https://apps.apple.com/cn/app/id1543361892#?platform=iphone",
          "_blank"
        );
      } else {
        window.open(
          "https://app.xiaomi.com/details?id=com.cngenesis.wave",
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
html 
  width 100%
  height 100%
  margin 0
  padding 0
body 
  width 100%
  height 100%
  margin 0
  padding 0
  font-size 24px
  font-family PingFangSC-Regular, sans-serif
  background-position center center
  background-color #ffffff
.container 
  width 100vw
  height 100vh
  background-image url("../../static/download-background.png")
  background-repeat no-repeat
  background-attachment fixed
  background-size cover
.logo 
  position relative
  width 100%
  height 100px
  overflow hidden
  background url("../../static/logo.png") center center no-repeat
  background-size auto 100%
.title 
  padding 12px 0 20px 0
  font-size 36px
  font-weight bold
  text-align center
.subhead 
  padding 6px 0
  font-size 24px
  font-weight bold
  text-align center
.intro 
  width 80%
  height auto
  overflow hidden
  margin 0 auto
  text-align center
  color #6b6969
  padding-bottom 20px
  p 
    font-size 9px
    color #6b6969
.download-button 
  width 320px
  height 50px
  margin 30px auto 0
  text-align center
  .button 
    display block
    float left
    width 140px
    height 50px
    cursor pointer
.button-ios 
  background url("../../static/download-apple.png") center center no-repeat
  background-size 100% auto
.button-android 
  background url("../../static/download-android.png") center center no-repeat
  background-size 100% auto
.download-text 
  width 320px
  margin 0 auto 50px
  text-align center
  .text 
    width 140px
    float left
    font-size 9px
    color #6b6969
.download-detail 
  width 320px
  margin 30px auto 0
  text-align center
  .detail 
    width 140px
    float left
    img 
      width 100%
.left-interval 
  margin-left 20px
.right-interval 
  margin-right 20px

</style>
